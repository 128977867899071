import { Snackbar, SnackbarProps } from '@material-ui/core'
import { Alert, AlertProps } from '@material-ui/lab'
import React, { createContext, useContext, useState } from 'react'

type FlashMessageContext = {
  setFlashMessage: (props: FlashMessageSnackbarProps) => void
}
const FlashMessageContext = createContext<FlashMessageContext>({
  setFlashMessage: () => undefined,
})
const { Provider } = FlashMessageContext

type FlashMessageSnackbarProps = SnackbarProps & Pick<AlertProps, 'severity'>

const FlashMessageProvider: React.FC = ({ children }) => {
  const [flashMessageProps, setFlashMessageProps] =
    useState<FlashMessageSnackbarProps>({})
  const [open, setOpen] = useState(false)

  function handleSetFlashMessage(props: FlashMessageSnackbarProps) {
    setOpen(true)
    setFlashMessageProps(props)
  }

  function handleClose() {
    setOpen(false)
    // Prevent flickering during the closing animation
    setTimeout(() => setFlashMessageProps({}), 100)
  }

  const value: FlashMessageContext = {
    setFlashMessage: handleSetFlashMessage,
  }

  const { severity, message, ...snackbarProps } = flashMessageProps

  return (
    <Provider value={value}>
      <Snackbar open={open} onClose={handleClose} {...snackbarProps}>
        <Alert severity={severity || 'info'} onClose={handleClose}>
          {message}
        </Alert>
      </Snackbar>
      {children}
    </Provider>
  )
}

export default FlashMessageProvider

export const useFlashMessage = (): FlashMessageContext =>
  useContext(FlashMessageContext)
